import React, { useState } from 'react';
// import { useRouter } from 'next/router';
import styled from 'styled-components';
// import Image from 'next/image';
import InviteModal from './InviteModal';
import { SP_SIZE } from '../utils/constants';
import { useHistory } from 'react-router-dom';

const Header = (props) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const history = useHistory();
  // const router = useRouter();
  const handleOnClick =(e) => {
    if (e.target.id === 'modal-content') {
      setIsOpenModal(false);
    }
  };

  const handleOnClickLogoLabel =(e) => {
    history.push('/');
  };

  return(
    <>
      <HeaderWrapper>
        <LeftItems>
          {/* <Image onClick={() => router.push("/")} src="/images/logo.svg" width={25} height={25} alt={"application-logo"} /> */}
          <LogoLabel onClick={handleOnClickLogoLabel}>Planning Poker</LogoLabel>
        </LeftItems>
        <RightItems>
          <InviteButton onClick={() => setIsOpenModal(!isOpenModal)}><PlusIcon>+</PlusIcon>Invite</InviteButton>
        </RightItems>
      </HeaderWrapper>
      { isOpenModal && (
        <UserListModelWrapper>
          <InviteModal url={props.url} onClick={handleOnClick} />
        </UserListModelWrapper>
      )}
    </>
  )
}

export default Header
const HeaderWrapper = styled.div`
  width: 100vw;
  height: 60px;
  border: 1px solid #CFCFCF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background: #fff;
  border-radius: 0 0 10px 10px;
`;

const LogoLabel = styled.div`
  color: #5A5B6A;
  font-size: 26px;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
  @media screen and (max-width: ${SP_SIZE}) {
		font-size: 20px;
  }
`;

const LeftItems = styled.div`
  padding-left: 10px;
  display: flex;
  align-items: center;
`;
const RightItems = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;
`;
// const InviteButton = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;  
//   border-radius: 50%;
//   width: 40px;
//   height: 40px;
//   background-color: gray;
//   &:hover {
//     cursor: pointer;
//   }
// `;
// const IconImage = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;  
// `;


const UserListModelWrapper = styled.div`
  position: absolute;
  right: 15px;
  top: 75px;
`;

const InviteButton = styled.button`
  display: block;
  font-size: 13px;
  padding: 8px;
  text-align: center;
  border-radius: 4px;
  transition: .4s; 
  letter-spacing: 2px;
  /* box-shadow: 0px 2px 4px #cfcfcf; */
  background-color: #fff;
  color: #2979FF;
  border: 1px solid #2979FF;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;
const PlusIcon = styled.span`
  font-size: 15px;
  margin-right: 2px;
  color: #2979FF;
`;