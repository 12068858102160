import React, { useRef, useState } from 'react';
import styled from 'styled-components';
// import Image from 'next/image';
import { SP_SIZE } from '../utils/constants';

const InviteModal = (props) => {
  const { onClick, url } = props;
  const inputRef = useRef(null);
  const  copyToClipboard = (e) => {
    inputRef.current.select();
    document.execCommand('copy');
    e.target.focus();
  };

  return(
    <ModalWrapper onClick={onClick} id="modal-content">
      <Content>
        <Title>招待したい人にリンクを共有してください</Title>
        <Desc>招待したい人に以下のリンク(URL)を伝えることでユーザーを部屋に招待することができます。</Desc>
        <InviteWrapper>
          <InviteLink value={url || ""} ref={inputRef} />
          <CopyButton onClick={copyToClipboard}>Copy</CopyButton>
        </InviteWrapper>
      </Content>
    </ModalWrapper>
  )
}

export default InviteModal

const ModalWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 4px #cfcfcf;
  /* overflow-y: scroll; */
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
`;
const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
  border-radius: 7px;
  border: solid 1px #CFCFCF;
  width: 90vw;
  padding: 50px 10px;
  max-width: 500px;
`;
const Title = styled.div`
  color: #5A5B6A;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  @media screen and (max-width: ${SP_SIZE}) {
		font-size: 14px;
  }
`;
const Desc = styled.div`
  font-size: 16px;
  text-align: center;
  @media screen and (max-width: ${SP_SIZE}) {
		font-size: 12px;
  }
`;
const InviteWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
`;
const InviteLink = styled.input`
	width: 300px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  font-size: 14px;
  padding: 8px 8px 8px 8px;
  margin-right: 6px;
  &::placeholder {
    color: #9e9e9e;
  }
  @media screen and (max-width: ${SP_SIZE}) {
		width: 180px;
  }
`;
const CopyButton = styled.button`
  display: block;
  width: 72px; 
  font-size: 16px;
  font-weight: bold;
  padding: 8px;
  text-align: center;
  border-radius: 4px;
  transition: .4s; 
  letter-spacing: 2px;
  box-shadow: 0px 2px 4px #cfcfcf;
  background-color: #2979FF;
  color: #fff;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;
