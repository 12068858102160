import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { sliceName } from '../utils/slice';

const Card = (props) => {
  const {
    number,
    cardType,
    onClick,
    isHidden,
    userName,
    displayName,
    canHover,
  } = props;
  const card = () => {
    switch (cardType) {
      case "front":
        return (
          <FrontCardWrapper onClick={onClick} isHidden={isHidden} canHover={canHover}>
            <CardContent>
              <CardTop>{number}</CardTop>
              <CardMain>{number}</CardMain>
              <CardBottom>{number}</CardBottom>
            </CardContent>
          </FrontCardWrapper>
        )
      case "back":
        return (
          <BackCardWrapper>
            <BackCard/>
          </BackCardWrapper>
        )
      case "dash":
        return (
          <DashedCard/>
        )
      case "empty":
        return (
          <EmptyCard/>
        )
      default:
        return null;
    }
  }
  return (
    <CardWrapper>
      <UserNameWrapper displayName={displayName && userName !== ""}>
        <UserName>{sliceName(userName, 5)}</UserName>
      </UserNameWrapper>
      {card(cardType)}
    </CardWrapper>
  )
}

export default Card;

const CardWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

const FrontCardWrapper = styled.div`
  opacity: ${props => props.isHidden ? '0' : '1'};
  width: 86px;
  height: 120px;
  background: #F0F0F1;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 4px;
  border: 1px solid #669DFB;
  transition: ${props => props.isHidden ? 'all 1.0s ease' : 'all .5s ease'};
  transform: ${props => props.isHidden ? 'translate(0px, -40px)' : 'translate(0px, 0px)'};
  cursor: pointer;

    ${props => props.canHover ? css`
      pointer-events: ${props => props.isHidden ? 'none' : 'auto'};
      &:hover {
        transform: ${props => props.isHidden ? 'translate(0px, -50px)' : 'translate(0px, -30px)'};
      }
    ` : css`
        pointer-events: ${props => props.isHidden ? 'none' : 'auto'};
      }
    `}
`;

const CardContent = styled.div`
  display: flex;
  flex-flow: column;
  align-items: space-between;
  height: 100%;
  justify-content: space-between;
  padding: 3px 5px;
  box-shadow: 0px 2px 4px #cfcfcf;
`;
const CardTop = styled.div`
  color: #669DFB;
`;
const CardMain = styled.div`
  color: #669DFB;
  font-size: 34px;
  text-align: center;
`;
const CardBottom = styled.div`
  color: #669DFB;
  text-align: right;
`;

const BackCardWrapper = styled.div`
  width: 86px;
  height: 120px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 4px;
  background: #669DFB;
  display: flex;
  align-items: center;
  justify-content: center;

  /* transform: ${props => props.isFront ? 'rotateY(-180deg)' : 'rotate(0)'} */

  &:hover {
    transition: all .6s ease;
    transform: rotateY(-180deg);
  }
`;

const BackCard = styled.div`
  width: 76px;
  height: 110px;
  border: 1px solid #fff;
  background: #669DFB;
  border-radius: 2px;
`;

const DashedCard = styled.div`
    width: 86px;
    height: 120px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 4px;
    border: 1px dashed #C4C4C4;
`;

const EmptyCard = styled.div`
    width: 86px;
    height: 120px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 4px;
    pointer-events: none;
`;

const UserNameWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 6px;
  opacity: ${props => props.displayName ? '1' : '0'};
`;

const UserName = styled.span`
  position: relative;
  background: #c4c4c4;
  padding: 2px 4px;
  border-radius: 4px;
  color: white;
  max-width: 70px;
  min-width: 20px;
  text-align: center;
  font-size: 12px;

  &:after{
    content: "";
    position: absolute;
    right: 5px;
    bottom: -4px;
    width: 0px;
    height: 0px;
    margin: auto;
    border-style: solid;
    border-color: #c4c4c4 transparent transparent transparent;
    border-width: 5px 5px 0 5px;
  }
`;
