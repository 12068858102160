import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { listenRoom } from '../../../client/firestore';
import Header from '../../../components/Header';
import HandCards from '../../../components/HandCards';
import Voting from '../../../components/Voting';
import Result from '../../../components/Result';
import { hostName } from '../../../utils/host';
import { sliceName } from '../../../utils/slice';
import { SP_SIZE } from '../../../utils/constants';
import { HelmetWrapper } from '../../../components/helmet';

const PlanningRoom = (props) => {
  const roomId = props.match.params.room_id;
  const userId = props.match.params.user_id;
	const [roomData, setRoomData] = useState(null);

  useEffect(async () => {
    await listenRoom(roomId, (resp) => setRoomData(resp));
  }, [roomId]);

  return (
    <Wrapper>
      { roomData && (
        <>
          <HelmetWrapper
            title={`${roomData.name}の部屋へようこそ`}
            description={`ブラウザだけで使えるWEBアプリのプラニングポーカーです。ログイン不要で完全無料！すぐに使い始められます。シンプルなUIでリアルタイムに集計！リモートワークやリモート会議でのフィボナッチ数を使った見積もりを簡単に行うことが出来ます。`}
          />
          <Header url={`${hostName()}/rooms/${roomId}/join`} />
          <RoomName>{sliceName(roomData.name, 20)}</RoomName>
          <RoomStateLabel>{roomData.is_voting ? "Voting": "Result"}</RoomStateLabel>
            <VotingWrapper isHidden={!roomData.is_voting}>
              <Voting roomData={roomData}></Voting>
            </VotingWrapper>
            <ResultWrapper isHidden={roomData.is_voting}>
              <Result roomData={roomData}></Result>
            </ResultWrapper>
          <HandCardsWrapper  isHidden={!roomData.is_voting}>
            <HandCards roomData={roomData} roomId={roomId} userId={userId}></HandCards>
          </HandCardsWrapper>
        </>
      )}
    </Wrapper>
  )
}

export default PlanningRoom;

const Wrapper = styled.div`
  background: #F8F8F8;
  height: 100vh;
  position: relative;
  overflow: hidden;
`;

const RoomName = styled.div`
  color: #9B9C9F;
  font-size: 18px;
  font-weight: bold;
  margin: 14px 0 0 20px;
  @media screen and (max-width: ${SP_SIZE}) {
		font-size: 14px;
    margin-bottom: 4px;
  }
`;
const HandCardsWrapper = styled.div`
  position: absolute;
  bottom: -200px;
  left: 0;
  right: 0;
  height: ${props => props.isHidden ? '260px' : 'auto'};
`;

const RoomStateLabel = styled.div`
  color: #9B9C9F;
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  transition: all .6s ease;
  opacity: ${props => props.isHidden ? '0' : '1'};
  @media screen and (max-width: ${SP_SIZE}) {
		font-size: 20px;
  }
`;

const VotingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  overflow-y: scroll;
  height: calc(100vh - 413px);
  margin-top: 20px;
  transition: all .6s ease;
  opacity: ${props => props.isHidden ? '0' : '1'};
  ${props => props.isHidden ? css`
    height: 0;
  ` : css`
  `}
`;

const ResultWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  align-items: flex-start;
  padding-top: 20px;
  transition: all .6s ease;
  opacity: ${props => props.isHidden ? '0' : '1'};
  overflow-y: scroll;
  height: calc(100vh - 211px);

    @media screen and (max-width: ${SP_SIZE}) {
		  display: flex;
    }
`;