import React from 'react';
import styled from 'styled-components'

export const NotFound = () => {
  return (
    <React.Fragment>
      <MainWrapper>
        <MainHead>Not Found 404</MainHead>
        <SubHead>指定されたページが見つかりませんでした</SubHead>
      </MainWrapper>
    </React.Fragment>
  )
}

const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-flow: column;
  color: #333333;
`;

const MainHead = styled.h1`
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 30px;
`;

const SubHead = styled.p`
  font-size: 14px;
`;
