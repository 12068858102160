import React from 'react';
import Card from './Card';
import styled, { css } from 'styled-components';
import { setVoteNumber, setIsVoting } from '../client/firestore';
import { clearVoteNumber } from '../client/firestore';
import { SP_SIZE } from '../utils/constants';

const cards = ["1", "2", "3", "5", "8", "13", "?", "∞"]

const HandCards = (props) => {
    const { roomData, roomId, userId } = props;
    const { users } = roomData;
    const voteNumber = roomData.users[userId].vote_number;
    const handleOnClickCard = async (number) => {
        await setVoteNumber(roomId, userId, number);
    }
    const handleOnClickOpen = async () => {
        await setIsVoting(roomId, false);
    }
    const handleOnClickClear = async () => {
        await setIsVoting(roomId, true);
        await clearVoteNumber(roomId, roomData.users);
    }
    return (
        <>
        <ButtonWrapper isHidden={!roomData.is_voting}>
                <OpenButton disabled={!roomData.is_voting} onClick={handleOnClickOpen}>Open</OpenButton>
                <CancelBtton disabled={roomData.is_voting} onClick={handleOnClickClear}>Reset</CancelBtton>
            </ButtonWrapper>
        <CardOperation isHidden={!roomData.is_voting}>
            
            <CardWrapper isHidden={!roomData.is_voting}>
                {cards.map((card, i) => {
                    return (
                        <Card
                            key={`${i}_hand_card`}
                            onClick={() => handleOnClickCard(card)}
                            cardType={"front"}
                            isHidden={card === voteNumber}
                            number={card}
                            canHover={true}
                        />
                    )
                })}
            </CardWrapper>
        </CardOperation>
        </>
    )
}

export default HandCards;

const CardOperation = styled.div`
    background: #fff;
    padding: 40px;
    position: relative;
    transition: all .6s ease;
    transform: ${props => props.isHidden ? 'translate(0px, 0px)' : 'translate(0px, -200px)'};
    border: 1px solid #CFCFCF;
    border-radius: 10px 10px 0px 0px;
    @media screen and (max-width: ${SP_SIZE}) {
        padding: 30px 30px 10px;
    }
`;
const CardWrapper = styled.div`
    display: flex;
    justify-content: center;
    @media screen and (max-width: ${SP_SIZE}) {
        > div {
            margin: 0 -34px;
        }
    }
`;
const ButtonWrapper = styled.div`
    display: flex;
    width: 210px;
    justify-content: space-between;
    transition: all .6s ease;
    transform: ${props => props.isHidden ? 'translate(0px, 0px)' : 'translate(0px, -200px)'};
    margin-left: auto;
    padding: 10px;
`;
const BaseButton = styled.button`
    display: block;
    width: 90px; 
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    text-align: center;
    border-radius: 10px;
    transition: .4s; 
    letter-spacing: 2px;
    box-shadow: 0px 2px 4px #cfcfcf;
`;
const OpenButton = styled(BaseButton)`
    background-color: #2979FF;
    color: #fff;

    ${props => props.disabled ? css`
        opacity: 0.4;
        cursor: default;

    ` : css`
        opacity: 1;
        &:hover {
            cursor: pointer;
            opacity: 0.7;
        }
    `}

`;
const CancelBtton = styled(BaseButton)`
    background-color: #fff;
    color: #565656;
    border: 1px solid #CFCFCF;
    font-weight: normal;

    ${props => props.disabled ? css`
        opacity: 0.4;
        cursor: default;

    ` : css`
        opacity: 1;
        &:hover {
            cursor: pointer;
            opacity: 0.7;
        }
    `}
`;