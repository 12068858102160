import React, { useState, useEffect } from 'react';
import Card from './Card';
import PCResult from './PCResult'
import SPResult from './SPResult'
import styled from 'styled-components';
import { SP_SIZE } from '../utils/constants';

const Result = (props) => {
    const { roomData } = props;
    const [ displayedName, setDisplayedName] = useState("");

    const handleOnClickCard = (name) => {
        console.log("displayedName: ", displayedName)
        setDisplayedName(name);
        console.log("displayedName: ", displayedName)
    }
    const users = roomData.users;
    const sorted = Object.keys(users).sort();
    const defaultResult =  {
        "1": [],
        "2": [],
        "3": [],
        "5": [],
        "8": [],
        "13": [],
        "?": [],
        "∞": [],
    };
    const result = sorted.reduce((accum, userId) => {
        const user = users[userId];
        const voteNumber = user.vote_number;
        if (voteNumber === "") return accum;

        const targetLst = accum[voteNumber];
        const next = targetLst.concat(user.name);
        accum[voteNumber] = next;
        return accum;
    }, defaultResult);

    const removeEmptyLst = Object.keys(result).filter(number => {
        const lst = result[number];
        return lst.length > 0;
    })
            
    const min = removeEmptyLst.reduce((accum, number) => {
        const lst = result[number];
        return accum > lst.length ? lst.length : accum;
    }, 99999);
    const max = removeEmptyLst.reduce((accum, number) => {
        const lst = result[number];
        return accum < lst.length ? lst.length : accum;
    }, 0);
    
    const colorCond = (number) => {
        if (max === number) {
            return "#669DFB";
        } else if (min === number) {
            return "#F6655A";
        }
        return "#B2B3C0";
    }
    return (
        <ResultWrapper>
            {Object.keys(result).map((number, i) => {
                const nameList = result[number];
                const color = colorCond(nameList.length);
                return (
                    <>
                    <PCResultWrapper key={`p_${i}_pc_resut_wrapper`}>
                        <PCResult key={`p_${i}_pc_result`} nameList={nameList} color={color} number={number} />
                    </PCResultWrapper>
                    <SPResultWrapper key={`s_${i}sp_result_wrapper`}>
                        <SPResult key={`s_${i}_sp_result`} displayedName={displayedName} onClick={setDisplayedName} nameList={nameList} color={color} number={number} />
                    </SPResultWrapper>
                    </>
                )
            })
        }
        </ResultWrapper>
    )
}

export default Result;

const ResultWrapper = styled.div`
    display: flex;
    flex-direction: row;
    @media screen and (max-width: ${SP_SIZE}) {
		display: flex;
        flex-direction: column;
        width: 100vw;
        height: 100%;
        padding-bottom: 10px;
    }
`;
const PCResultWrapper = styled.div`
    display: flex;
    @media screen and (max-width: ${SP_SIZE}) {
		display: none;
    }
`;
const SPResultWrapper = styled.div`
    display: none;    
    @media screen and (max-width: ${SP_SIZE}) {
		display: flex;
    }
`;