import { createUuid } from '../utils/uuid';
import firebase from 'firebase';

const ROOM_COLLECTION_NAME = "rooms";
const initDB = () => firebase.firestore();

export const getRooms = () => {
  const db = initDB();
  db.collection(ROOM_COLLECTION_NAME).get()
  .then((room) => {
    return room
  })
  .catch((err) => {
    console.error(err);
  })
};

export const getRoom = async (roomId) => {
  const db = initDB();
  const resp = await db.collection(ROOM_COLLECTION_NAME)
  .doc(roomId)
  .get()
  .then((doc) => {
    return doc.data();
  })
  .catch((err) => {
    console.error(err);
  })
  return resp;
};

export const createRoom = async (name) => {
  const db = initDB();
  const roomsDocId = db.collection(ROOM_COLLECTION_NAME).doc().id;
  const resp = await db.collection(ROOM_COLLECTION_NAME).doc(roomsDocId).set({
    // docId: docId,
    name: name,
    is_voting: true,
    users: {},
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  })
  .then(() => {
    return roomsDocId;
  })
  .catch((error) => {
    console.error("Error adding document: ", error);
    return "";
  });
  return resp;
}

export const addUser = async (roomId, name) => {
  const db = initDB();
  const userId = createUuid()
  const resp = await db.collection(ROOM_COLLECTION_NAME).doc(roomId).set({
    users: {
      [userId]: {
        name: name,
        joined_at: firebase.firestore.FieldValue.serverTimestamp(),
        vote_number: "",
      }
    }
  }, {merge: true})
  .then(() => {
    return userId;
  })
  .catch((error) => {
    console.error("Error adding document: ", error);
    return "";
  });
  return resp;
}

export const setVoteNumber = async (roomId, userId, voteNumber) => {
  const db = initDB();
  const resp = await db.collection(ROOM_COLLECTION_NAME).doc(roomId)
  .update({
    [`users.${userId}.vote_number`]: voteNumber,
  }, {merge: true})
  .then(() => {
    return true;
  })
  .catch((error) => {
    console.error("Error adding document: ", error);
    return false;
  });
  return resp;
}

export const setIsVoting = async (roomId, isVonting) => {
  const db = initDB();
  const resp = await db.collection(ROOM_COLLECTION_NAME).doc(roomId)
  .update({ is_voting: isVonting })
  .then(() => {
    return true;
  })
  .catch((error) => {
    console.error("Error adding document: ", error);
    return false;
  });
  return resp;
}

export const clearVoteNumber = async (roomId, users) => {
  const db = initDB();
  const userIds = Object.keys(users);
  const updateQuery = userIds.reduce((accum, userId) => {
    const key = `users.${userId}.vote_number`;
    accum[key] = "";
    return accum;
  }, {})
  const resp = await db.collection(ROOM_COLLECTION_NAME).doc(roomId)
  .update(updateQuery, {merge: true})
  .then(() => {
    return true;
  })
  .catch((error) => {
    console.error("Error adding document: ", error);
    return false;
  });
  return resp;
}

export const listenRoom = async (roomId, callback) => {
  const db = initDB();
  const resp = await db.collection(ROOM_COLLECTION_NAME).doc(roomId)
  .onSnapshot(function(doc) {
    callback(doc.data());
  });
  return resp;
}
