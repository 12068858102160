import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Create from "../pages/rooms/create";
import Join from "../pages/rooms/[room_id]/join";
import PlanningRoom from "../pages/rooms/[room_id]/[user_id]";
import Home from "../pages/index";
import { NotFound } from './not_found';

const SetRouter = () => {
  return (
    <Router>
      <Switch>
        {/* <Route exact path='/' component={Home}></Route> */}
        <Route exact path='/'  component={Create}></Route>
        <Route path="/rooms/:room_id/join" component={Join}></Route>
        <Route path="/rooms/:room_id/:user_id" component={PlanningRoom}></Route>
        <Route component={NotFound} />
      </Switch>
    </Router>
  )
}

export default SetRouter;
