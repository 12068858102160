import React, { useState, useEffect } from 'react';
import Card from './Card';
import styled from 'styled-components';
import { SP_SIZE } from '../utils/constants';

const PCResult = (props) => {
    const { nameList, color, number } = props;
    return (
        <CountWrapper>
            <CountLabelWrapper color={color}>
                <TotalNumbur color={color}>{nameList.length}</TotalNumbur>
                <UnitLabel color={color}>枚</UnitLabel>
            </CountLabelWrapper>
            <CardWrapper>
                {nameList.length === 0 && (
                        <>
                        <Card
                            onClick={null}
                            cardType={"empty"}
                            isHidden={false}
                            number={number}
                            userName={""}
                            displayName={false}
                            canHover={false}
                        />
                        </>
                )}
                {nameList.map((name, i) => {
                    return (
                        <CardMargin key={`${i}_pc_result_wrapper`}>
                            <Card
                                key={`${i}_pc_result_card`}
                                onClick={null}
                                cardType={"front"}
                                isHidden={false}
                                number={number}
                                userName={name}
                                displayName={true}
                                canHover={false}
                            />
                        </CardMargin>
                    )
                })}
            </CardWrapper> 
        </CountWrapper>
    )
}

export default PCResult;

const ResultWrapper = styled.div`
    display: flex;
    @media screen and (max-width: ${SP_SIZE}) {
		display: none;
  }
`;

const CountWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px;
`;

const CardMargin = styled.div`
    margin-bottom: -116px;
`;

const CountLabelWrapper = styled.div`
    display: flex;
    align-items: baseline;
    border-bottom: 1px solid;
    border-color: ${props => props.color};
    justify-content: center;
    padding-bottom: 3px;
`;

const TotalNumbur = styled.div`
    color: ${props => props.color};
`;

const UnitLabel = styled.div`
    font-size: 10px;
    color: ${props => props.color};
`;

const CardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 8px;
`;
