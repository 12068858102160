import App from '../components/App'
import styled from 'styled-components';
import { SP_SIZE } from '../utils/constants';
import { useHistory } from 'react-router-dom';
import { HelmetWrapper } from '../components/helmet';

const Home = () => {
  const history = useHistory();
  const handleOnClick = () => {
    history.push('/rooms/create');
  };

  return (
    <App>
      <HelmetWrapper
        title={`ブラウザで出来る！WEBオンラインプラニングポーカー`}
        description={`ブラウザだけで使えるWEBアプリのプラニングポーカーです。ログイン不要で完全無料！すぐに使い始められます。シンプルなUIでリアルタイムに集計！リモートワークやリモート会議でのフィボナッチ数を使った見積もりを簡単に行うことが出来ます。`}
      />
      <Wrapper>
        <SubWrapper>
          <ContentWrapper>
            <Title>Planning Poker</Title>
            <Desc>
              WEBで出来るプラニングポーカー<br/>
              アプリのインストール必要なし！<br/>
              チームメイトをURLで簡単招待！<br/>
              最高の見積もりを始めよう！
            </Desc>
            <PcButtonWrapper>
              <WhiteButton onClick={handleOnClick}>Start</WhiteButton>
            </PcButtonWrapper>
          </ContentWrapper>
          <VideoWrapper>
            <VideoContent autoPlay muted loop>
              <VideoSource src="/video/converted-demo-video.mp4" type="video/mp4" />
            </VideoContent>
          </VideoWrapper>
          <SpButtonWrapper>
            <WhiteButton onClick={handleOnClick}>Start</WhiteButton>
          </SpButtonWrapper>
        </SubWrapper>
        <SubWrapper2>
          <ContentWrapper2>
            <FeatureTitleLabel>特徴</FeatureTitleLabel>
            <FeaturesWrapper>
              <Feature>
                <FeatureTitleWrapper>
                  <FeatureIcon>1</FeatureIcon>
                  <FeatureTitle>リアルタイム反映</FeatureTitle>
                </FeatureTitleWrapper>
                <FeatureDesc>投票・集計が参加者全員の画面にリアルタイムに反映されます。</FeatureDesc>
              </Feature>
              <Feature>
                <FeatureTitleWrapper>
                  <FeatureIcon>2</FeatureIcon>
                  <FeatureTitle>ログイン不要</FeatureTitle>
                </FeatureTitleWrapper>
                <FeatureDesc>アカウントを作成する必要はありません。すぐに使い始めることができます。</FeatureDesc>
              </Feature>
              <Feature>
                <FeatureTitleWrapper>
                  <FeatureIcon>3</FeatureIcon>
                  <FeatureTitle>完全無料</FeatureTitle>
                  </FeatureTitleWrapper>
                <FeatureDesc>全ての機能が無料で使えます。有料プランはありません。</FeatureDesc>
              </Feature>
            </FeaturesWrapper>
          </ContentWrapper2>
          <ContentWrapper3>
            <StartText>さっそく始める</StartText>
            <StartCaption>最高の見積もりを今すぐに</StartCaption>
            <StartButtonWrapper>
              <BlueButton onClick={handleOnClick}>Start</BlueButton>
            </StartButtonWrapper>
          </ContentWrapper3>
        </SubWrapper2>
      </Wrapper>
    </App>
  )
}

export default Home;


const Wrapper = styled.div``;
const SubWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background: url("/images/reverse-low-poly-texture-2.webp");
  /* background-size: cover; */
  background-position: left bottom;
  background-size: 130%;
  background-repeat: no-repeat;

  display: flex;
  justify-content: center;
  align-items: center;
  /* transform: rotate(-0.03deg); */

  @media screen and (max-width: ${SP_SIZE}) {
    flex-direction: column;
    background-size: 430%;
    position: relative;
    justify-content: space-around
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px;
  @media screen and (max-width: ${SP_SIZE}) {
    align-items: center;
  }
`;
const VideoWrapper = styled.div`
  width: 650px;
  margin-left: 20px;
  @media screen and (max-width: ${SP_SIZE}) {
    width: 90vw;
    margin-left: 0px;
  }
`;
const VideoContent = styled.video`
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 4px #7b7b7b;
`;
const VideoSource = styled.source`
`;

const PcButtonWrapper = styled.div`
  display: block;
  @media screen and (max-width: ${SP_SIZE}) {
    display: none;
  }
`;
const SpButtonWrapper = styled.div`
  padding: 10px;
  display: none;
  @media screen and (max-width: ${SP_SIZE}) {
    display: block;
  }
`;
const Title = styled.div`
  height: 100%;
  color: white;
  font-size: 55px;
  font-weight: 900;
  height: 68px;
  margin-bottom: 24px;
  margin-top: 0px;
  @media screen and (max-width: ${SP_SIZE}) {
    font-weight: normal;
    font-size: 46px;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 0px;
  }
`;
const Desc = styled.div`
  padding: 10px;
  padding-left: 0px;
  color: white;
  font-size: 16px;
  line-height: 26px;
  height: 98px;
  font-weight: bold;
  margin-bottom: 30px;
  @media screen and (max-width: ${SP_SIZE}) {
    padding-left: 10px;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    height: 100%;
    margin-bottom: 0px;
  }
`;

const BaseButton = styled.button`
  display: block;
  width: 180px; 
  height: 50px;
  font-size: 16px;
  font-weight: bold;
  /* padding: 10px; */
  text-align: center;
  border-radius: 30px;
  transition: .4s; 
  letter-spacing: 2px;
  box-shadow: 0px 2px 4px #cfcfcf;
  &:hover {
      cursor: pointer;
      opacity: 0.8;
  }
  @media screen and (max-width: ${SP_SIZE}) {
    width: 90vw;
    height: 60px;
  }
`;
const WhiteButton = styled(BaseButton)`
  background-color: #fff;
  color: #38A3F1;
`;
const BlueButton = styled(BaseButton)`
  background-color: #38A3F1;
  color: #fff;
`;
const SubWrapper2 = styled.div`
  display: flex;
  justify-content: space-around;
  flex-flow: column;
  height: 100vh;
  align-items: center;
  @media screen and (max-width: ${SP_SIZE}) {
    height: auto;
  }
`;
const ContentWrapper2 = styled.div`
  /* height: 50vh; */
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;
`;

const FeatureTitleLabel = styled.div`
  width: 200px;
  height: 77px;
  font-size: 46px;
  margin: 20px;
  text-align: center;
  color: #252B42;
  @media screen and (max-width: ${SP_SIZE}) {
    font-size: 34px;
    margin: 30px 0 0 0;
  }
`;

const FeaturesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${SP_SIZE}) {
    flex-direction: column;
  }
`;

const Feature = styled.div`
  width: 255px;
  height: 154px;
  margin: 25px;
  @media screen and (max-width: ${SP_SIZE}) {
    margin-top: 10px;
    margin-bottom: 0;
  }
`;

const FeatureTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #252B42;
  margin-bottom: 15px;
`;

const FeatureIcon = styled.div`
  width: 43px;
  height: 43px;
  background: #669DFB;
  border-radius: 50%;
  text-align: center;
  line-height: 43px;
  color: white;
  margin-right: 10px;
`;

const FeatureTitle = styled.div`
  color: #252B42;
  font-size: 22px;
  font-weight: bold;
`;

const FeatureDesc = styled.div`
  color: #374754;
  width: 255px;
  height: 80px;
  font-size: 18px;
  line-height: 27px;
`;

const ContentWrapper3 = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: ${SP_SIZE}) {
    margin-bottom: 40px;
  }

`;

const StartText = styled.div`
  color: #252B42;
  font-size: 46px;
  height: 77px;
  @media screen and (max-width: ${SP_SIZE}) {
    font-size: 34px;
  }
`;

const StartCaption = styled.div`
  color: #374754;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
`;

const StartButtonWrapper = styled.div`

`;