import React, { useState } from 'react';
import styled from 'styled-components';
import { createRoom } from '../../client/firestore';
import { SP_SIZE } from '../../utils/constants';
import { useHistory } from 'react-router-dom';
import { HelmetWrapper } from '../../components/helmet';
import logo from '../../../src/site_logo.svg';

const Create = () => {
	const [inputValue, setInputValue] = useState("");
	const history = useHistory();
	const handleOnClick = async (e) => {
		if(inputValue.length !== 0) {
			const roomId = await createRoom(inputValue);
      console.log(`/rooms/${roomId}/join`);
			history.push(`/rooms/${roomId}/join`);
		}
	}
	const handleChange = (event) => {
		setInputValue(event.target.value);
	}
	const handleOnClickLogo =(e) => {
		history.push('/');
	};
  return (
    <React.Fragment>
			<HelmetWrapper
        title={`部屋を作成しよう | WEBオンラインプラニングポーカー`}
        description={`ブラウザだけで使えるWEBアプリのプラニングポーカーです。ログイン不要で完全無料！すぐに使い始められます。シンプルなUIでリアルタイムに集計！リモートワークやリモート会議でのフィボナッチ数を使った見積もりを簡単に行うことが出来ます。`}
      />
      <Wrapper>
        <CreateFormWrapper>
					<InnerWrapper>
						<LogoWrapper>
							<LogoImage onClick={handleOnClickLogo} src={logo} alt={"application-logo"}></LogoImage>
						</LogoWrapper>
						<WelcomeWrapper>
							<WelcomeLabel>Welcome</WelcomeLabel>
						</WelcomeWrapper>
						<InnerFormWrapper>
							<CreateForm>
								<Label>部屋の名前</Label>
								<Inputs placeholder={"例: 春の見積もり大会"} value={inputValue} onChange={handleChange} />
								<Button onClick={(e) => handleOnClick(e)}>Create</Button>
							</CreateForm>
							<AboutApp href={"https://eni-eni.com/%e3%83%96%e3%83%a9%e3%82%a6%e3%82%b6%e3%81%a7%e5%87%ba%e6%9d%a5%e3%82%8bweb%e3%82%aa%e3%83%b3%e3%83%a9%e3%82%a4%e3%83%b3%e3%83%97%e3%83%a9%e3%83%8b%e3%83%b3%e3%82%b0%e3%83%9d%e3%83%bc%e3%82%ab/"}>このアプリについて</AboutApp>
						</InnerFormWrapper>
					</InnerWrapper>
        </CreateFormWrapper>
      </Wrapper>
    </React.Fragment>
  )
}

const Wrapper = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
  background-color: #F0F0F1;
`;

const CreateFormWrapper = styled.div`
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	@media screen and (max-width: ${SP_SIZE}) {
		width: 100vw;
	}
`;

const InnerWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;

const LogoWrapper = styled.div`
	width: 100%;
	margin: 20px;
	@media screen and (max-width: ${SP_SIZE}) {
		width: 70px;
  	}
`;
const WelcomeWrapper = styled.div`

`;

const InnerFormWrapper = styled.div`
	height: calc(100vh - 100px);
	width: 100%;
	display: flex;
	/* justify-content: center; */
	align-items: center;
	flex-flow: column;
`;


const LogoImage = styled.img`
	width: 70px;
	height: 70px;
`;

const WelcomeLabel = styled.div`
	font-size: 35px;
	height: 15vh;
	display: flex;
	justify-content: center;
	align-items: center;
	letter-spacing: 1px;
	font-family: 'Raleway', sans-serif;

`;

const CreateForm = styled.div`
	width: 80%;
	max-width: 450px;
	display: flex;
	flex-flow: column;
	justify-content: center;
	margin-top: 5vh;
`;
const InputsWrapper = styled.div`
	
`;
const Label = styled.div`
  margin: 0 0 4px 8px;
  font-size: 14px;
  letter-spacing: 1px;
`;
const Inputs = styled.input`
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  font-size: 14px;
  padding: 8px 8px 8px 8px;
  margin-bottom: 20px;
  &::placeholder {
    color: #9e9e9e;
  }
`;
const Button = styled.button`
  display: block;  
  width: 100%;
  background-color: #2979FF;
  font-size: 16px;
  color: #FFF;
  font-weight: bold;
  padding: 10px;
  text-align: center;
  border-radius: 4px;
  transition: .4s; 
  letter-spacing: 2px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const AboutApp = styled.a`
	color: #a5a5a5;
    margin-top: 30px;
    text-decoration: none;
    border-bottom: 1px solid #a5a5a5;
    padding: 1px;
    font-size: 15px;
`;

export default Create;
