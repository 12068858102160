import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { getRoom, addUser } from '../../../client/firestore';
import { SP_SIZE } from '../../../utils/constants';
import { sliceName } from '../../../utils/slice';
import { HelmetWrapper } from '../../../components/helmet';
import logo from '../../../../src/site_logo.svg'


const Join = (props) => {
	const history = useHistory();
  const [inputValue, setInputValue] = useState("");
	const roomId = props.match.params.room_id;
	const [roomData, setRoomData] = useState({});
	const handleOnClick = async (e) => {
		if(inputValue.length !== 0) {
			const userId = await addUser(roomId, inputValue);
      console.log(`/rooms/${roomId}/${userId}`);
	  	history.push(`/rooms/${roomId}/${userId}`);
		}
	}
  const handleChange = (event) => {
    setInputValue(event.target.value);
  }
  const handleOnClickLogo =(e) => {
    history.push('/');
  };

  useEffect(async () => {
    const roomData = await getRoom(roomId);
    setRoomData(roomData);
  }, [roomId]);

  return (      
    <React.Fragment>
			{ roomData && (
				<>
					<HelmetWrapper
						title={`${roomData.name}に参加しよう | WEBオンラインプラニングポーカー`}
						description={`ブラウザだけで使えるWEBアプリのプラニングポーカーです。ログイン不要で完全無料！すぐに使い始められます。シンプルなUIでリアルタイムに集計！リモートワークやリモート会議でのフィボナッチ数を使った見積もりを簡単に行うことが出来ます。`}
					/>
					<Wrapper>
						<CreateFormWrapper>
							<InnerWrapper>
								<LogoWrapper>
									<LogoImage onClick={handleOnClickLogo} src={logo} alt={"application-logo"}></LogoImage>
								</LogoWrapper>
									<>
										<WelcomeWrapper>
											<WelcomeLabel>
												<WelcomeDiv>
													<RoomName>{sliceName(roomData.name, 8)}</RoomName>
													<LableSpan>に入室します</LableSpan>
												</WelcomeDiv>
											</WelcomeLabel>
										</WelcomeWrapper>
										<InnerFormWrapper>
											<CreateForm>
													<Label>名前</Label>
													<Inputs placeholder={"例: 見積もり太郎"} value={inputValue} onChange={handleChange} />
													<Button onClick={(e) => handleOnClick(e)}>Join</Button>
											</CreateForm>
											<AboutApp href={"https://eni-eni.com/%e3%83%96%e3%83%a9%e3%82%a6%e3%82%b6%e3%81%a7%e5%87%ba%e6%9d%a5%e3%82%8bweb%e3%82%aa%e3%83%b3%e3%83%a9%e3%82%a4%e3%83%b3%e3%83%97%e3%83%a9%e3%83%8b%e3%83%b3%e3%82%b0%e3%83%9d%e3%83%bc%e3%82%ab/"}>このアプリについて</AboutApp>
										</InnerFormWrapper>
									</> 
							</InnerWrapper>
						</CreateFormWrapper>
					</Wrapper>
	  		</>
			)}
    </React.Fragment>
  )
}

const Wrapper = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
  	background-color: #F0F0F1;
`;
const CreateFormWrapper = styled.div`
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	@media screen and (max-width: ${SP_SIZE}) {
		width: 100vw;
  	}
	
`;
const InnerWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;

const LogoWrapper = styled.div`
	width: 100%;
	margin: 20px;

	@media screen and (max-width: ${SP_SIZE}) {
		width: 70px;
  	}
`;

const LogoImage = styled.img`
	width: 70px;
	height: 70px;
`;

const WelcomeWrapper = styled.div`
`;

const InnerFormWrapper = styled.div`
	height: calc(100vh - 100px);
	width: 100%;
	display: flex;
	/* justify-content: center; */
	align-items: center;
	flex-flow: column;
`;

const WelcomeLabel = styled.div`
	font-size: 35px;
	height: 15vh;
	display: flex;
	justify-content: center;
	align-items: center;
	letter-spacing: 1px;
	/* font-family: 'Raleway', sans-serif; */
`;

const WelcomeDiv = styled.div`
    display: flex;
	align-items: baseline;

`;

const RoomName = styled.div`
	@media screen and (max-width: ${SP_SIZE}) {
		font-size: 24px;
  	}
`;

const LableSpan = styled.span`
    font-size: 16px;
	@media screen and (max-width: ${SP_SIZE}) {
		font-size: 12px;
  	}
`;


const CreateForm = styled.div`
	width: 80%;
	max-width: 450px;
	display: flex;
	flex-flow: column;
	justify-content: center;
	margin-top: 5vh;
`;
const Label = styled.div`
  margin: 0 0 4px 8px;
  font-size: 14px;
  letter-spacing: 1px;
`;
const Inputs = styled.input`
	width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  font-size: 14px;
  padding: 8px 8px 8px 8px;
  margin-bottom: 20px;
  &::placeholder {
    color: #9e9e9e;
  }
`;
const Button = styled.button`
  display: block;  
  width: 100%;
  background-color: #2979FF;
  font-size: 16px;
  color: #FFF;
  font-weight: bold;
  padding: 10px;
  text-align: center;
  border-radius: 4px;
  transition: .4s; 
  letter-spacing: 2px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const AboutApp = styled.a`
	color: #a5a5a5;
    margin-top: 30px;
    text-decoration: none;
    border-bottom: 1px solid #a5a5a5;
    padding: 1px;
    font-size: 15px;
`;

export default Join;
