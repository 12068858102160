import React from 'react';
import Card from '../components/Card';
import styled from 'styled-components';

const Voting = (props) => {
    const { roomData } = props;
    const users = roomData.users;
    const sorted = Object.keys(users).sort();
    return (
        <CardWrapper>
            {sorted.map( (userId, i) => {
                const cardType = users[userId].vote_number === "" ? "dash" : "back";
                return (
                    <CardMargin key={`${i}_voting_card_margin`}>
                        <Card
                            key={`${i}_voting_card`}
                            onClick={null}
                            cardType={cardType}
                            isHidden={false}
                            number={users[userId].vote_number}
                            userName={users[userId].name}
                            displayName={true}
                        />
                    </CardMargin>
                )
            })}
        </CardWrapper>
    )
}

export default Voting;

const CardWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
    margin-left: -5px;
`;
const CardMargin = styled.div`
    margin-bottom: 5px;
    margin-top: 5px;
`;
