import React from 'react';
import Card from './Card';
import styled from 'styled-components';

const SPResult = (props) => {
    const { nameList, color, number, displayedName, onClick } = props;

    const handleOnclick = (displayedName) => {
        onClick(displayedName);
    }

    return (
        <CountWrapper>
            <CountLabelWrapper color={color}>
                <TotalNumbur color={color}>{nameList.length}</TotalNumbur>
                <UnitLabel color={color}>枚</UnitLabel>
            </CountLabelWrapper>
            <CardWrapper>
                {nameList.length === 0 && (
                        <>
                        <Card
                            onClick={null}
                            cardType={"empty"}
                            isHidden={false}
                            number={number}
                            userName={""}
                            displayName={false}
                            canHover={false}
                        />
                        </>
                )}
                {nameList.map((name, i) => {
                    return (
                        <CardMargin key={`${i}_pc_result_wrapper`}>
                            <Card
                                key={`${i}_result_card`}
                                onClick={() => handleOnclick(name)}
                                cardType={"front"}
                                isHidden={false}
                                number={number}
                                userName={name}
                                displayName={name === displayedName}
                                canHover={false}
                            />
                        </CardMargin>
                    )
                })}
            </CardWrapper> 
        </CountWrapper>
    )
}

export default SPResult;

const CountWrapper = styled.div`
    display: flex;
    margin: 0px 20px 0 20px;
`;

const CardMargin = styled.div`
    margin-right: -70px;
`;

const CountLabelWrapper = styled.div`
    display: flex;
    align-items: baseline;
    border-right: 1px solid;
    border-color: ${props => props.color};
    justify-content: center;
    padding-bottom: 3px;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    text-combine-upright: all;
    -webkit-text-combine: horizontal;
    -ms-text-combine-horizontal: all;
    text-orientation: upright;
    margin-right: 14px;
    margin-top: 31px;
`;

const TotalNumbur = styled.div`
    color: ${props => props.color};
`;

const UnitLabel = styled.div`
    font-size: 10px;
    color: ${props => props.color};
`;

const CardWrapper = styled.div`
    display: flex;
    margin-top: 8px;
`;
