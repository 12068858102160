import React from 'react';
import ReactDOM from 'react-dom';
import firebase from "firebase";

import './styles/reset.css'
import './styles/styles.css'
import './index.css';
import reportWebVitals from './reportWebVitals';
import SetRouter from './routes/index';

const firebaseConfig = {
  apiKey: "AIzaSyANPPE237ovM-wn3TTliYFkkOIGTICHUHM",
  authDomain: "planning-poker-2496d.firebaseapp.com",
  projectId: "planning-poker-2496d",
  storageBucket: "planning-poker-2496d.appspot.com",
  messagingSenderId: "827097706914",
  appId: "1:827097706914:web:33674cf662b0acbfd702fb",
  measurementId: "G-FXEQS6NJCC"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

ReactDOM.render(
  <React.StrictMode>
    <SetRouter />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
